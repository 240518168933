<template>
  <div class="order-line-info">
    <div class="order-line-info-item">
      <div class="icon">
        <svg t="1653878767353" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="29442" width="100%" height="100%"><path d="M832 960H192c-35.328 0-64-28.672-64-64V128c0-35.328 28.672-64 64-64h640c35.328 0 64 28.672 64 64v768c0 35.328-28.672 64-64 64zM192 128v768h640V128H192z m96 96H640v64H288v-64z m0 160H512v64H288V384z" fill="#121216" p-id="29443"></path></svg>
      </div>
      <div class="info">
        <p class="text">{{ data.func_form }}</p>
        <p class="label">表单编号</p>
      </div>
    </div>
    <div class="order-line-info-item">
      <div class="icon">
        <svg t="1653879945264" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="29587" width="100%" height="100%"><path d="M640 736a128 128 0 0 1 123.968 96H960v64l-196.032 0.064A128 128 0 0 1 640 992H576a128 128 0 0 1-123.968-95.936L64 896v-64h388.032A128 128 0 0 1 576 736h64z m0 64H576a64 64 0 0 0-7.488 127.552L576 928h64a64 64 0 0 0 0-128zM384 384a128 128 0 0 1 123.968 96H960v64l-452.032 0.064A128 128 0 0 1 384 640H320a128 128 0 0 1-123.968-95.936L64 544v-64h132.032A128 128 0 0 1 320 384h64z m0 64H320a64 64 0 0 0-7.488 127.552L320 576h64a64 64 0 1 0 0-128z m320-416A128 128 0 0 1 827.968 128H960v64l-132.032 0.064A128 128 0 0 1 704 288h-64a128 128 0 0 1-123.968-95.936L64 192V128h452.032A128 128 0 0 1 640 32h64z m0 64h-64a64 64 0 0 0-7.488 127.552L640 224h64a64 64 0 0 0 0-128z" fill="#2C374D" p-id="29588"></path></svg>
      </div>
      <div class="info">
        <p class="text">
          <span v-if="data.status === 1">已处理</span>
          <span v-else>处理中</span>
        </p>
        <p class="label">表单状态</p>
      </div>
    </div>
    <div class="order-line-info-item">
      <div class="icon">
        <svg t="1653880050200" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="29736" width="100%" height="100%"><path d="M960 960h-64v-160a160.192 160.192 0 0 0-160-160V576a224.256 224.256 0 0 1 224 224V960z m-256 0h-64v-160A160.192 160.192 0 0 0 480 640h-192A160.192 160.192 0 0 0 128 800V960H64v-160A224.256 224.256 0 0 1 288 576h192a224.256 224.256 0 0 1 224 224V960zM640 64v64a160 160 0 1 1 0 320v64a224 224 0 0 0 0-448zM384 128a160 160 0 1 1 0 320 160 160 0 0 1 0-320m0-64a224 224 0 1 0 0 448 224 224 0 0 0 0-448z" fill="#000000" p-id="29737"></path></svg>
      </div>
      <div class="info">
        <p class="text">{{ data.target_users }}</p>
        <p class="label">责任人</p>
      </div>
    </div>
    <div class="order-line-info-item">
      <div class="icon">
        <svg t="1653880395427" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="29881" width="100%" height="100%"><path d="M1024 1024H64V128h256V0h64v128h320V0h64v128h256v896z m-64-576H128v512h832V448z m0-256h-192v128h-64V192H384v128H320V192H128v192h832V192z" fill="#2C374D" p-id="29882"></path></svg>
      </div>
      <div class="info">
        <p class="text">{{ data.deadline | formaterTime }}</p>
        <p class="label">截止时间</p>
      </div>
    </div>
  </div>
</template>
<script>
/**
 * @description 工单行详情
 */
export default {
  name: 'OrderLineInfo',
  props: {
    data: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  filters: {
    formaterTime(v) {
      if(typeof v === 'string') {
        const date = v.substring(0, 16)
        return date.replaceAll('-', '/')
      } else {
        return '/'
      }
    }
  },
  computed:{
    getUsers(){
      return function(target){
        const name = Array.from(target,item=>item.name)
        return name.join(',')
      }
    },
  },
}
</script>
<style lang="less" scoped>
  .order-line-info{
    width: 100%;
    height: 62px;
    display: flex;
    border-top: 1px solid #f1f1f1;
    border-bottom: 1px solid #f1f1f1;
    .order-line-info-item{
      flex-grow: 1;
      display: flex;
      align-items: center;
      padding: 0 32px;
      .icon{
        width: 32px;
        height: 32px;
        margin-right: 16px;
        padding: 8px;
        box-sizing: border-box;
        border-radius: 16px;
        background: #F2F4F8;
      }
      .info{
        .text{
          height: 22px;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #2A2F3D;
          line-height: 22px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .label{
          height: 18px;
          margin-top: 2px;
          font-size: 12px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #6D768F;
          line-height: 18px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
      & + .order-line-info-item{
        border-left: 1px solid #f1f1f1;
      }
    }
  }
</style>
