<template>
  <div class="message-item-container">
    <div class="message-warp" @click.stop="handleReply">
      <div class="avatar">
       <el-avatar :size="32" shape="square" src="https://empty" @error="errorHandler">
          <img :src="data.create_user_img"/>
        </el-avatar>
      </div>
      <div class="message-main">
        <p class="info">
          <span class="name">
            {{ data.create_user_name }}
          </span>
          <span class="time">
            {{ data.created_at | formaterTime }}
          </span>
          <span v-if="currentUser.id === data.create_user_id" class="message-item-action" @click.stop="">
            <el-dropdown placement="bottom">
              <span class="el-dropdown-link">
                <svg t="1655437040894" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="30556" width="14" height="14"><path d="M224 512m-80 0a80 80 0 1 0 160 0 80 80 0 1 0-160 0Z" p-id="30557"></path><path d="M512 512m-80 0a80 80 0 1 0 160 0 80 80 0 1 0-160 0Z" p-id="30558"></path><path d="M800 512m-80 0a80 80 0 1 0 160 0 80 80 0 1 0-160 0Z" p-id="30559"></path></svg>
              </span>
              <el-dropdown-menu slot="dropdown">
                <!-- <el-dropdown-item>
                  <span @click="handleEditorItem"><i class="iconfont icongis_bianji" />编辑</span>
                </el-dropdown-item> -->
                <el-dropdown-item>
                  <el-popconfirm
                    confirm-button-text='删除'
                    cancel-button-text='取消'
                    icon="el-icon-info"
                    icon-color="red"
                    @confirm="handleDeleteItem"
                    title="删除后不可恢复，确定删除吗？"
                  >
                    <span style="color: red" slot="reference"><i class="iconfont iconc-trash-can" />删除</span>
                  </el-popconfirm>
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </span>
        </p>
        <p class="message">
          <span v-if="data.to_user_name">
            回复
            <span class="to-name">
              {{ data.to_user_name }}
            </span>
          </span>
          {{ data.content }}
        </p>
      </div>
    </div>
    <div class="message-sub-warp" v-if="Array.isArray(data.children) && data.children.length">
      <template v-for="(item) in data.children">
        <MessageItem :key="item.id" :data="item" @reply="handleSubReply" @delete="handleSubDelete" @editor="handleSubEditor"/>
      </template>
    </div>
  </div>
</template>
<script>
import { Avatar, Popconfirm } from 'element-ui'
import { getUserInfo } from '@/utils/tools.js'
export default {
  name: 'MessageItem',
  components: {
    'el-avatar': Avatar,
    'el-popconfirm': Popconfirm
  },
  data() {
    return {
      currentUser: getUserInfo()
    }
  },
  props: {
    data: {
      type: Object,
      default() { return {} }
    }
  },
  filters: {
    formaterTime(v = '') {
      const date = new Date(v)
      const New = new Date()
      if(isNaN(date.getTime())) {
        return '-'
      }
      const y = date.getFullYear()
      const m = date.getMonth()
      const d = date.getDate()
      const ny = New.getFullYear()
      const nm = New.getMonth()
      const nd = New.getDate()
      // 同年
      if (y === ny) {
        if(m === nm) {
          if (d === nd) {
            return v.substring(11, 16)
          } else if(d === nd - 1) {
            return `昨天${v.substring(11, 16)}`
          } else {
            return v.substring(5, 11).replaceAll('-', '/')
          }
        } else {
          return v.substring(5, 11).replaceAll('-', '/')
        }
      } else {
        return v.substring(0, 11).replaceAll('-', '/')
      }
    }
  },
  methods: {
    handleReply() {
      this.$emit('reply', this.data)
    },
    handleSubReply(data) {
      this.$emit('reply', data)
    },
    errorHandler() {
      return true
    },
    handleDeleteItem() {
      this.$emit('delete', this.data)
    },
    handleEditorItem() {
      this.$emit('editor', this.data)
    },
    handleSubDelete(data) {
      this.$emit('delete', data)
    },
    handleSubEditor(data) {
      this.$emit('editor', data)
    }

  }
}
</script>
<style lang="less" scoped>
  .message-item-container{
    border-bottom: 1px solid #f1f1f1;
    width: 100%;
    .message-warp{
      display: flex;
      .avatar{
        width: 32px;
        height: 32px;
        margin: 4px 12px;
      }
      .message-main{
        flex-grow: 1;
        width: 10px;
        padding-top: 4px;
        padding-bottom: 4px;
        box-sizing: border-box;
        .info{
          display: flex;
          align-items: center;
          .name{
            height: 22px;
            font-size: 14px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #4A5D8F;
            line-height: 22px;
          }
          .time{
            margin-left: 8px;
            height: 18px;
            font-size: 12px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #787E8F;
            line-height: 18px;
          }
          .message-item-action{
            margin-left: 10px;
          }
        }
        .message{
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #121619;
          line-height: 22px;
          .to-name{
            color: #4A5D8F;
            margin: 0 4px;
          }
        }
      }
    }
    .message-sub-warp{
      padding-left: 44px;
      box-sizing: border-box;
      :deep(.message-item-container) {
      border: none;
    }
    }
  }
</style>
