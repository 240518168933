<template>
  <div class="order-sub-item" :class="activeItem.id === data.id && activeItem.type === data.type ? 'active-item' : ''" @click.stop="handleItemClick">
    <div class="icon">
      <svg t="1653623083938" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="29294" width="100%" height="100%"><path d="M960 384v448a64 64 0 0 1-64 64H128a64 64 0 0 1-64-64V384h896zM421.504 128a64 64 0 0 1 45.248 18.752l26.496 26.496A64 64 0 0 0 538.496 192H896a64 64 0 0 1 64 64v64H64V192a64 64 0 0 1 64-64h293.504z" fill="#FFB700" p-id="29295"></path></svg>
    </div>
    <div class="text">
      {{ data.nameTitle }}
    </div>
    <div class="status">
      {{ data.status | formaterStstus }}
    </div>
  </div>
</template>
<script>
export default{
  name: 'OrderSubItem',
  props: {
    data: {
      type: Object,
      default() {
        return {}
      }
    },
    activeItem: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  filters: {
    formaterStstus(v) {
      const msg = {
        1: '已完成',
        2: '待处理'
      }
      return msg[v] ? msg[v] : '-'
    }
  },
  methods: {
    handleItemClick() {
      this.$emit('subItemClick', this.data)
    }
  }
}
</script>
<style lang="less" scoped>
  .order-sub-item{
    width: 100%;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 40px;
    box-sizing: border-box;
    .icon{
      width: 20px;
      height: 20px;
      margin-right: 8px;
    }
    .text{
      flex-grow: 1;
      width: 10px;
      height: 22px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #2A2F3D;
      line-height: 22px;
    }
    .status{
      margin: 0 16px 0 8px;
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #787E8F;
      line-height: 18px;
    }
    &:hover{
      cursor: pointer;
    }
  }
  .active-item{
    border: 2px solid rgba(51, 126, 255, 0.64);
    box-sizing: border-box;
    border-radius: 6px;
  }
</style>