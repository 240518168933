<template>
  <div class="order-menu">
    <Item :activeItem="value" :data="headerItem" :type="'status'" @itemClick="handleItemClick" />
    <template v-for="(item, index) in list">
      <Item :activeItem="value" :data="item" :key="index"  @itemClick="handleItemClick"/>
    </template>
  </div>
</template>
<script>
/**
 * @description 工单左侧选项
 */
import Item from './Item.vue'
import eventBus from "@/plugins/eventBus";
export default {
  components: { Item },
  props: {
    list: {
      type: Array,
      default() {
        return []
      }
    },
    value: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  computed: {
    // 顶部统计项
    headerItem() {
      let item = {
        id: 0,
        nameTitle: '工单详情',
        statusText: '0个待完成，0个已完成',
      }
      const data = this.list
      if(Array.isArray(data)) {
        const isOvered = data.filter(el => el.status === 0).length
        const need = data.filter(el => el.status === 1).length
        item.statusText = `${isOvered}个待完成，${need}个已完成`
      }
      this.$emit('input', item)
      this.$emit('change', item)
      return item
    }
  },
  mounted() {
    eventBus.$on('show-form',({node})=>{
      this.initRoute(node)
    })
  },
  methods: {
    initRoute(node){
      if(node){
        if(this.list.length){
          const data = this.list.filter(item=>item.additional.step_node === node)
          if(data.length){
            this.handleItemClick(data[0]);
          }
        }
      }
    },
    handleItemClick(item) {
      this.$emit('input', item)
      this.$emit('change', item)
    }
  }
}
</script>
<style lang="less" scoped>
  .order-menu{
    width: 266px;
    height: 100%;
    overflow-y: auto;
    padding: 0px 8px 16px 16px;
    box-sizing: border-box;
  }
</style>
