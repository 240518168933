<template>
  <div v-loading="loading" class="test-container">
    <UDLayout :margin="16">
        <PageHeader slot="top" :title="title" :back="handleBack" v-if="!dataId">
          <el-button class="action-btn" v-if="canUseAction" @click.stop="handleEditor">
            <i class="iconfont icongis_bianji" />
            编辑
          </el-button>
          <el-button class="action-btn" v-if="canUseAction" @click.stop="handleClose">
            <i class="iconfont icontinggong" />
            关闭
          </el-button>
        </PageHeader>
        <LRLayout slot="bottom" :margin="4">
          <OrderMenu slot="left" v-if="list" v-model="activeItem" @change="getSelectNode" :list="list" />
          <BGCard slot="right" :padding="'0 72px 0 4px'">
            <!-- 统计详情 -->
            <OrderInfo
              v-if="activeItem.id === 0 && formList"
              :workOrderId="workOrderId"
              :taskEngineId="taskEngineId"
              :taskRecoverId="taskRecoverId"
              :taskTodo="taskTodo"
              :list="formList" />
            <!-- 大项详情 -->
            <OrderInfoItem
              v-else-if="activeItem.id !== 0 && activeItem.type !== 'child' && nodeData"
              :node-data="nodeData" @refresh="refresh" />
            <!-- 小项详情 -->
            <OrderInfoSubItem
              v-else-if="activeItem.id !== 0 && activeItem.type === 'child' && nodeData"
              :node-data="nodeData" @refresh="refresh" />
          </BGCard>
        </LRLayout>
    </UDLayout>
  </div>
</template>
<script>
import PageHeader from './components/PageHeader.vue'
import UDLayout from './components/UDLayout.vue'
import LRLayout from './components/LRLayout.vue'
import OrderMenu from './components/orderMenu'
import BGCard from './components/BGCard.vue'
import OrderInfo from './pages/OrderInfo.vue'
import OrderInfoItem from './pages/OrderInfoItem.vue'
import OrderInfoSubItem from './pages/OrderInfoSubItem.vue'
import { dataInterface } from '@/apis/data';
export default {
  name: 'Test',
  components: { UDLayout, PageHeader, LRLayout, OrderMenu, BGCard, OrderInfo, OrderInfoItem, OrderInfoSubItem },
  props:['dataId'],
  data() {
    return {
      // 当前活动项
      activeItem: {},
      // 右侧列表
      list: null,
      formList: null,
      // 源数据
      sourceData: null,
      // 页头标题
      title: '',
      // 源数据loading
      loading: false,
      workOrderId: 0,
      taskEngineId: 0,
      taskRecoverId: 0,
      nodeData: null,
      currentID: 0,
      taskTodo: [],
    }
  },
  computed: {
    // 能否使用页头操作按钮
    canUseAction() {
      return false
    },
  },
  created() {
    this.currentID = this.dataId || this.$route.query.id
    this.getSourceData()
  },
  methods: {
    getSelectNode(data){
      this.nodeData = data.additional
    },
    // 获取源数据
    getSourceData() {
      const reqDara = {
        __method_name__: 'dataInfo',
        object_uuid: 'object62946b8979e52',
        view_uuid: 'view62956d844d681',
        data_id: this.currentID,
        transcode: 0
      }
      this.loading = true
      dataInterface(reqDara).then(res => {
        if (res.data.code === 200) {
          this.title = `工单详情：${res.data.data.name}`
          this.sourceData = res.data.data
          this.workOrderId = res.data.data.id
          this.taskEngineId = res.data.data.task_engine_id
          this.taskRecoverId = res.data.data.task_recover_id
          this.getLeftData()
        }
      }).finally(() => {
        this.loading = false
      })
    },
    getLeftData(){
      const reqData = {
        object_data_id: this.workOrderId,
        task_engine_id: this.taskEngineId
      }
      dataInterface(reqData,'/api/taskengine/tasktodo','GET').then(res => {
        if (res.data.code === 200) {
          const data = res.data.data
          this.taskTodo = data
          const copyData = []
          const formList = []
          for (let item of data){
            item.nodeData.status = item.status
            formList.push(item.nodeData)
            const itemData = {
              id: item.id,
              nameTitle: item.name,
              statusText: item.status === 0 ? '处理中' : '已处理',
              status: item.status,
              additional: item,
              children:[]
            }
            for (let eleItem of item.user_task){
              item.userWait = eleItem
              const child = {
                nameTitle: eleItem.user_name,
                status: eleItem.is_deal,
                id: eleItem.id,
                type: 'child',
                additional: item,
              }
              itemData.children.push(child)
            }
            copyData.push(itemData)
          }
          this.formList = formList
          this.list = copyData
        }
      })
    },
    // 页头顶部返回事件
    handleBack() {
      this.$router.push({path:'/work-order'})
    },
    // 编辑事件
    handleEditor() {

    },
    // 关闭事件
    handleClose() {

    },
    refresh(){
      this.getSourceData()
    },
  }
}
</script>
<style lang="less" scoped>
  .test-container{
    width: 100%;
    height: 100%;
    background: #f5f5f5;
  }
</style>
