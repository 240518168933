<template>
  <div class="left-and-right-layout">
    <div class="left">
      <slot name="left"></slot>
    </div>
    <div class="right" :style="`margin-left: ${ margin }px`">
      <slot name="right"></slot>
    </div>
  </div>
</template>
<script>
/**
 * @description 左右布局
 */
export default {
  name: "LRLayout",
  props: {
    margin: {
      type: Number,
      default: 0
    }
  }
}
</script>
<style lang="less" scoped>
  .left-and-right-layout{
    width: 100%;
    height: 100%;
    display: flex;
    .left{
      height: 100%;
    }
    .right{
      height: 100%;
      flex-grow: 1;
      width: 10px;
    }
  }
</style>
